:root {
  --spacing-1: 4px;
  --spacing-2: 8px;
  --spacing-3: 16px;
  --spacing-4: 32px;
  --spacing-5: 48px;

  --border-color: rgba(217, 212, 173, 0.3);
  --bg-color: rgba(217, 212, 173, 0.1);
  --bg-color-hover: rgba(217, 212, 173, 0.2);

  --error-color: #ff2323;

  --condition-indent: var(--spacing-3);
}

html {
  --scroll-behavior: smooth !important;
  scroll-behavior: smooth !important;
}

body {
  font-family: var(--font-body);
  font-size: 16px;
  color: white;
  min-height: 100vh;
  background: url(/_next/static/media/zodiac-bg.e94d592f.svg) 0% 0% / cover fixed;
  margin: 0px;
}
body::before {
  content: " ";
  display: block;
  position: fixed;
  z-index: -9999;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(
    108.86deg,
    rgb(12 16 32 / 85%) 6.24%,
    rgb(8 14 5 / 85%) 53.08%,
    rgb(24 4 3 / 85%) 96.54%
  );
}

a {
  color: white;
  cursor: pointer;
  text-decoration: underline;
}

a:hover {
  color: rgba(252, 248, 217, 0.8);
}

h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 73px;
  text-align: center;
  margin: 0;
}

h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  margin: 0;
}

h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}

p {
  margin: 0;
}

input:not([type="checkbox"]),
textarea,
select {
  font-size: 14px;
  background-color: transparent;
  font-family: var(--font-mono), monospace;
  color: white;
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(217, 212, 173, 0.8);
  line-height: 20px;
  padding: var(--spacing-2) 10px;
  height: 38px;
  transition: all 0.1s ease;
}

input:hover:enabled,
textarea:hover:enabled select:hover:enabled {
  border-color: white;
}

input:active,
textarea:active,
select:active,
input:focus,
textarea:focus,
select:focus {
  border: 1px solid white;
  outline: none;
}

option {
  padding: 0;
}

code {
  font-family: var(--font-mono), monospace;
  padding: 4px 8px;
  background: #3e3d2ad1;
  border-radius: 4px;
  border: 1px solid rgba(217, 212, 173, 0.3);
}

/* Works on Firefox*/
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(217, 212, 173, 0.8) rgba(217, 212, 173, 0.1);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: none;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(217, 212, 173, 0.3);
  border-radius: 0px;
  cursor: pointer;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: rgba(217, 212, 173, 0.5);
  border-radius: 0px;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a905a6179bab2b08-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/273b2e150de7c4a9-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/692781be160fc9d0-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1e9fc5e960416d85-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e244d3ca994d3054-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Spectral Fallback';src: local("Times New Roman");ascent-override: 96.46%;descent-override: 42.17%;line-gap-override: 0.00%;size-adjust: 109.78%
}.__className_f31ab7 {font-family: 'Spectral', 'Spectral Fallback';font-weight: 400;font-style: normal
}.__variable_f31ab7 {--font-body: 'Spectral', 'Spectral Fallback'
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/77ce01c003603e77-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ccd5f404b5d91fc0-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/78bcd7d80ba05af5-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a9ae0bb0e1e9484e-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5269767769affeeb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0a7d1127b1849c3a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/77ce01c003603e77-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ccd5f404b5d91fc0-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/78bcd7d80ba05af5-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a9ae0bb0e1e9484e-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/5269767769affeeb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/0a7d1127b1849c3a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Roboto Mono Fallback';src: local("Arial");ascent-override: 77.84%;descent-override: 20.13%;line-gap-override: 0.00%;size-adjust: 134.61%
}.__className_a7d1c0 {font-family: 'Roboto Mono', 'Roboto Mono Fallback';font-style: normal
}.__variable_a7d1c0 {--font-mono: 'Roboto Mono', 'Roboto Mono Fallback'
}

